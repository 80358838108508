import React from 'react'
import { Wrapper, H } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { WILLS_NEW_TRIAGE_FLOW_FRAMING_URL } from 'config'
import WrapperWithEdge from 'components/WrapperWithEdge'
import WillsProductFlowButton from '../WillsProductFlowButton'

const GetStarted = ({
  isPartnerCampaignReferral,
  telephoneWillsEnabled,
}: {
  isPartnerCampaignReferral: boolean
  telephoneWillsEnabled: boolean
}): React.ReactElement => (
  <WrapperWithEdge direction="up" edgeBackgroundColor={COLOR.WHITE}>
    <Wrapper container containerPaddingTop="L">
      <Wrapper centered maxWidth={550} margin={[0, 'auto']}>
        <H size="L" decorative>
          Ready to tick your will off your to-do list?
        </H>
      </Wrapper>

      <Wrapper margin={['L', 'auto', 0]} centered>
        <WillsProductFlowButton
          buttonProps={{
            'data-track-event': 'click',
            'data-track-element': 'footer-get-started',
          }}
          href={WILLS_NEW_TRIAGE_FLOW_FRAMING_URL}
          queryParams={{
            isPartnerCampaignReferral,
            telephoneWillsEnabled,
          }}
        >
          Let’s get started
        </WillsProductFlowButton>
      </Wrapper>
    </Wrapper>
  </WrapperWithEdge>
)

export default GetStarted
