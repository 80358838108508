import React from 'react'
import styled from 'styled-components'
import { BackgroundImageWrapper } from '@farewill/ui'

import { ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS } from 'config'
import ThreeEasySteps from 'components/ThreeEasySteps'

const StyledEm = styled(BackgroundImageWrapper)`
  font-style: normal;
  padding: 0 0.2em 0 0;
  background-size: 100% 30%;
  background-position: bottom;
`

const WhyWriteYourWillToday = ({
  cta,
}: {
  cta: React.ReactNode
}): React.ReactElement => (
  <>
    <ThreeEasySteps
      title={
        <>
          Why you should write your will{' '}
          <StyledEm
            tag="em"
            imagePath="textures/yellow-text-underline"
            imageWidth={125}
          >
            today
          </StyledEm>
        </>
      }
      steps={[
        {
          imagePath: 'illustrations/square/blob-with-laptop-and-cat',
          heading: 'It’s easier than you think',
          text: 'No matter how you write your will, we’ll cut the legal jargon to make it simpler. If you get stuck, we’re only a phone call away.',
        },
        {
          imagePath: 'illustrations/couples-exchange',
          heading: 'You can change your mind later',
          imageWidthFromL: 230,
          text: `Write your online will with confidence. Our unlimited update service is just £${ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS} a year, so you can change it any time.`,
        },
        {
          imagePath: 'illustrations/tv-wills-hero-without-plant',
          imageWidthFromL: 238,
          heading: 'It’ll make all the difference',
          text: 'Writing a will is one of the simplest ways to protect the people you love. Tick it off your to-do list for peace of mind.',
        },
      ]}
      ctaPrompt="Why wait?"
      cta={cta}
    />
  </>
)

export default WhyWriteYourWillToday
