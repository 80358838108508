import React from 'react'
import { capitalize } from 'lodash-es'
import { graphql, useStaticQuery } from 'gatsby'
import { Wrapper, Divider, H, P } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'

import {
  CHARITY_AMOUNT_RAISED,
  FLOW_WILLS_URL,
  WILLS_NEW_TRIAGE_FLOW_FRAMING_URL,
} from 'config'
import { PRODUCTS } from 'lib/products/constants'
import AwardBanner from 'components/AwardBanner'
import BreakpointSwitch from 'components/BreakpointSwitch'
import CharitiesList from 'components/CharitiesList'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import PreventOrphan from 'components/PreventOrphan'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'

import {
  ESSENTIAL_WILL,
  GUIDED_WILL,
  COMPLEX_WILL,
} from 'views/wills/components/WillOptions/constants'

import Hero from '../Hero'
import WillOptions from '../WillOptions'
import WillsProductFlowButton from '../WillsProductFlowButton'
import WhyWriteYourWillToday from '../WhyWriteYourWillToday'
import GetStarted from './GetStarted'
import ContactUs from './ContactUs'

/**
 * This component should only be shown if telephone wills are enabled, so this
 * value can be assumed to be true. Partners and campaigns that do not offer
 * telephone wills are excluded from the A/B experiment by configuration in
 * Contentful which is read in src/lib/gatsby/node/createPagesStatefully/partnerCampaigns.ts.
 */
const TELEPHONE_WILLS_ENABLED = true

export type ExptRecommendWillProps = {
  heroTitle?: string | null
  heroSubtitle?: string | null
  isPartnerCampaignPage?: boolean
}

const isExperimentPage = true

const ExptRecommendWill = ({
  heroTitle,
  heroSubtitle,
  isPartnerCampaignPage = false,
}: ExptRecommendWillProps): React.ReactElement => {
  const { faqSection } = useStaticQuery(graphql`
    query {
      faqSection: contentfulFaqSection(
        entryTitle: { eq: "Wills A/B experiment Q3 2023" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  return (
    <Wrapper background={COLOR.WHITE}>
      <Wrapper container>
        <Hero
          title={heroTitle || 'The UK’s favourite will writing service'}
          subtitle={
            heroSubtitle ||
            'Write your will online or over the phone and take care of what really matters.'
          }
          isPartnerCampaignPage={isPartnerCampaignPage}
          telephoneWillsEnabled={TELEPHONE_WILLS_ENABLED}
          isExperimentPage={isExperimentPage}
        />
      </Wrapper>

      <Divider container />

      <Wrapper container>
        <BreakpointSwitch
          breakAt={BREAKPOINT.S}
          belowComponent={<TrustpilotCarousel />}
          aboveComponent={<TrustpilotReviews showTitle />}
        />
      </Wrapper>

      <WillOptions
        isFree={isPartnerCampaignPage}
        edgeBackgroundColor={COLOR.WHITE}
        options={
          isPartnerCampaignPage
            ? [ESSENTIAL_WILL, GUIDED_WILL]
            : [ESSENTIAL_WILL, GUIDED_WILL, COMPLEX_WILL]
        }
        footer={
          <Wrapper
            centered
            margin={['XL', 0, 0]}
            marginFromXL={['XL', 0, `-${GTR.M}`]}
          >
            <P strong>Not sure which will to choose?</P>
            <WillsProductFlowButton
              buttonProps={{
                forward: true,
                'data-track-event': 'click',
                'data-track-element': 'will-options-quiz',
              }}
              href={FLOW_WILLS_URL}
              queryParams={{
                isPartnerCampaignReferral: isPartnerCampaignPage,
                telephoneWillsEnabled: true, // This component should only be shown if telephone wills are enabled
              }}
            >
              Take the quiz
            </WillsProductFlowButton>
          </Wrapper>
        }
      />

      <Wrapper paddingFromL={['S', 0, 'XL']}>
        <AwardBanner
          title="Will Writing Firm of the Year, 4 years in a row"
          paragraph="For generations, the process of writing a will has been slow, expensive and inconvenient. We created Farewill to change that. Since our launch, we’ve helped tens of thousands of people across the UK to make a will. And every year since 2019 we’ve been crowned National Will Writing Firm of the Year at the British Wills and Probate Awards."
          showDecorativeColor
        />
      </Wrapper>

      <WhyWriteYourWillToday
        cta={
          <WillsProductFlowButton
            buttonType="Primary"
            buttonProps={{
              'data-track-event': 'click',
              'data-track-element': 'why-wait-get-started',
            }}
            href={WILLS_NEW_TRIAGE_FLOW_FRAMING_URL}
            queryParams={{
              isPartnerCampaignReferral: isPartnerCampaignPage,
              telephoneWillsEnabled: TELEPHONE_WILLS_ENABLED,
            }}
          >
            Get started today
          </WillsProductFlowButton>
        }
      />

      <Wrapper container>
        <FrequentlyAskedQuestions
          title="Frequently asked questions"
          questions={faqSection.questions}
          selectedProduct={PRODUCTS.WILLS}
          telephoneWillsEnabled={TELEPHONE_WILLS_ENABLED}
          showCallUsTodayCta
          customCta={
            <Wrapper margin={['L', 0, 0]}>
              <ContactUs />
            </Wrapper>
          }
        />
      </Wrapper>

      <Divider container />

      <Wrapper container>
        <H size="L" decorative>
          <PreventOrphan>
            {capitalize(CHARITY_AMOUNT_RAISED)} pledged to charity
          </PreventOrphan>
        </H>
        <P maxWidthInColumns={7}>
          Our generous customers have pledged {CHARITY_AMOUNT_RAISED} to our
          charity partners by leaving a gift in their wills.
        </P>

        <Wrapper
          background={COLOR.BACKGROUND.FOG}
          bordered
          borderRadius="M"
          padding={['M', 0]}
          margin={['L', 0, 0]}
        >
          <CharitiesList withMoreCharitiesText />
        </Wrapper>
      </Wrapper>

      <GetStarted
        isPartnerCampaignReferral={isPartnerCampaignPage}
        telephoneWillsEnabled={TELEPHONE_WILLS_ENABLED}
      />
    </Wrapper>
  )
}

export default ExptRecommendWill
