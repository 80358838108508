export const PRESELECTED_SERVICE_VALUES = {
  ONLINE: 'online', // control
  TELEPHONE: 'telephone', // control
  ESSENTIAL: 'essential', // experiment
  GUIDED: 'guided', // experiment
  COMPLEX: 'complex', // experiment
} as const

export type PreselectedServiceValues =
  (typeof PRESELECTED_SERVICE_VALUES)[keyof typeof PRESELECTED_SERVICE_VALUES]
