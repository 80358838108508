import React, { useContext } from 'react'
import { SplitContext } from '@splitsoftware/splitio-react'
import { FF_EXPT_RECOMMEND_WILL_ENABLED } from 'config'

import { CONTROL } from 'lib/split/constants'
import { useExperiment } from 'lib/hooks/useExperiment'
import { useRecordSplitPageview } from 'lib/hooks/useRecordSplitPageview'
import { updateExperimentCookie } from 'lib/split/helpers'
import LoaderSpinner from 'components/LoaderSpinner'

import Control from './variants/Control'
import ExptRecommendWill from './variants/ExptRecommendWill'

const OverviewABWrapper = (): React.ReactElement => {
  const exptRecommendWillTreatment = useExperiment('ExptRecommendWill')
  const { isReady } = useContext(SplitContext)
  useRecordSplitPageview('wills_landing_page_view', undefined, {
    source: 'direct',
  })

  // If the Wills landing page experiment is not enabled, show the
  // default page (and don't block rendering on Split checking the variant).
  if (!FF_EXPT_RECOMMEND_WILL_ENABLED) {
    return <Control />
  }

  if (!isReady) {
    return <LoaderSpinner centered />
  }

  // If ExptCombinedWillsLP is not active, then check if we are running
  // the ExptRecommendWill experiment and defer to that for the variant to show.
  updateExperimentCookie('ExptRecommendWill', exptRecommendWillTreatment)
  switch (exptRecommendWillTreatment) {
    case 'experiment_variant':
      return <ExptRecommendWill />

    case 'control_variant':
    case CONTROL:
    default:
      return <Control />
  }
}

export default OverviewABWrapper
