import React from 'react'
import { Button } from '@farewill/ui'

import {
  ONLINE_WILL_PRICE_IN_POUNDS,
  ONLINE_WILL_COUPLES_PRICE_IN_POUNDS,
  PRINTED_WILL_PRICE_IN_POUNDS,
  ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS,
  TELEPHONE_WILL_PRICE_IN_POUNDS,
  TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS,
  TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS,
  TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
  FLOW_WILLS_URL,
  FAREWILL_ACCOUNT_APP_URL,
  WILLS_SIGN_UP_PATH,
} from 'config'

import { PRESELECTED_SERVICE_VALUES } from 'lib/url/wills/constants'
import LinkButton from 'components/LinkButton'

import { WillOption } from './types'
import WillsProductFlowButton from '../WillsProductFlowButton'

export const ESSENTIAL_WILL: WillOption = {
  title: 'The Essential Will',
  description:
    'Write your will online with our step-by-step tool. Checked by a will specialist. Great for simple wills and estates.',
  singlePrice: ONLINE_WILL_PRICE_IN_POUNDS,
  couplesPrice: ONLINE_WILL_COUPLES_PRICE_IN_POUNDS,
  optionalExtras: [
    {
      name: 'Professionally printed will',
      cost: `£${PRINTED_WILL_PRICE_IN_POUNDS}`,
    },
    {
      name: 'Unlimited updates',
      cost: `£${ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS} a year`,
    },
  ],
  cta: ({ isFree, telephoneWillsEnabled }) => (
    <WillsProductFlowButton
      href={FLOW_WILLS_URL}
      buttonType="Bordered"
      buttonProps={{
        'data-track-event': 'click',
        'data-track-element': 'will-option-cta',
        'data-track-product': 'essential-will',
      }}
      queryParams={{
        preselectedService: PRESELECTED_SERVICE_VALUES.ESSENTIAL,
        isPartnerCampaignReferral: isFree,
        telephoneWillsEnabled,
      }}
    >
      Select the Essential Will
    </WillsProductFlowButton>
  ),
}

export const GUIDED_WILL: WillOption = {
  title: 'The Guided Will',
  description:
    'Write your will with a specialist. Great for talking things through and getting support where you need it.',
  singlePrice: TELEPHONE_WILL_PRICE_IN_POUNDS,
  couplesPrice: TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS,
  optionalExtras: [
    {
      name: 'Professionally printed will',
      cost: `£${PRINTED_WILL_PRICE_IN_POUNDS}`,
    },
    {
      name: 'Add trusts or business assets',
      cost: `£${TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS}`,
    },
  ],
  cta: ({ isFree, telephoneWillsEnabled }) => (
    <WillsProductFlowButton
      href={`${FAREWILL_ACCOUNT_APP_URL}/wills/write-your-guided-will`}
      buttonType="Bordered"
      buttonProps={{
        'data-track-event': 'click',
        'data-track-element': 'will-option-cta',
        'data-track-product': 'guided-will',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(isFree && {
        queryParams: {
          isPartnerCampaignReferral: isFree,
          telephoneWillsEnabled,
        },
      })}
    >
      Select the Guided Will
    </WillsProductFlowButton>
  ),
}

export const COMPLEX_WILL: WillOption = {
  title: 'The Complex Will',
  description:
    'Write your will with a specialist and include unlimited trusts, bespoke clauses and business assets.',
  singlePrice:
    TELEPHONE_WILL_PRICE_IN_POUNDS +
    TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS,
  couplesPrice:
    TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS +
    2 * TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS,
  footerText: 'Trusts and business assets included',
  cta: ({ isFree, telephoneWillsEnabled }) => (
    <WillsProductFlowButton
      href={`${FAREWILL_ACCOUNT_APP_URL}/wills/write-your-complex-will`}
      buttonType="Bordered"
      buttonProps={{
        'data-track-event': 'click',
        'data-track-element': 'will-option-cta',
        'data-track-product': 'complex-will',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(isFree && {
        queryParams: {
          isPartnerCampaignReferral: isFree,
          telephoneWillsEnabled,
        },
      })}
    >
      Select the Complex Will
    </WillsProductFlowButton>
  ),
}

// The following two will types are intended to be temporary and we'll stop
// using them when we change back to offering 3 types of will (Essential,
// Guided, Complex - as above).
export const ONLINE_WILL: WillOption = {
  title: 'Online wills',
  description:
    'Write your will online with our step-by-step tool. Checked by a will specialist. Great for simple wills and estates.',
  singlePrice: ONLINE_WILL_PRICE_IN_POUNDS,
  couplesPrice: ONLINE_WILL_COUPLES_PRICE_IN_POUNDS,
  optionalExtras: [
    {
      name: 'Unlimited updates',
      cost: `£${ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS} a year`,
    },
    {
      name: 'Professionally printed will',
      cost: `£${PRINTED_WILL_PRICE_IN_POUNDS}`,
    },
  ],
  cta: ({ isFree, telephoneWillsEnabled }) => (
    <LinkButton
      buttonTag={Button.BorderedDark}
      to={WILLS_SIGN_UP_PATH}
      preselectedService={PRESELECTED_SERVICE_VALUES.ONLINE}
      partnerCampaignReferral={isFree}
      telephoneWillsEnabled={telephoneWillsEnabled}
      productLink
      stretch
      wide={false}
      data-track-event="click"
      data-track-element="will-option-cta"
      data-track-product="online-will"
    >
      Get started for free
    </LinkButton>
  ),
}

export const TELEPHONE_WILL: WillOption = {
  title: 'Telephone wills',
  description:
    'Write your will with a specialist. Great for talking things through and getting support where you need it.',
  singlePrice: TELEPHONE_WILL_PRICE_IN_POUNDS,
  couplesPrice: TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS,
  optionalExtras: [
    {
      name: 'Add trusts or business assets',
      cost: `£${TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS}`,
    },
    {
      name: 'Professionally printed will',
      cost: `£${PRINTED_WILL_PRICE_IN_POUNDS}`,
    },
  ],
  cta: () => (
    <Button.BorderedDark
      href={TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL}
      data-track-event="click"
      data-track-element="will-option-cta"
      data-track-product="telephone-will"
    >
      Book my free callback
    </Button.BorderedDark>
  ),
}
