import React from 'react'
import styled from 'styled-components'
import { Wrapper, Divider, H, P, PhoneIcon } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { PRODUCTS, PRODUCTS_MAP, SPECIALISTS } from 'lib/products/constants'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import PhoneLineOpenIndicator from 'components/PhoneLineOpenIndicator'
import Specialists from 'components/Specialists'
import TelephoneNumber from 'components/TelephoneNumber'

const StyledSmallScreenContentWrapper = styled(Wrapper)`
  ${screenMin.l`
    display: none;
  `}
`

const StyledTelephoneNumber = styled(TelephoneNumber)`
  width: 100%;
  max-width: 320px; // This mimics the wide style of the UI library Button
  padding-top: ${GTR.S};
`

const StyledLargeScreenContentWrapper = styled(Wrapper)`
  display: none;

  ${screenMin.l`
    display: block;
    margin-right: -${GTR.XXL};
  `}
`

const StyledSpecialistsWrapper = styled(Wrapper)`
  display: inline-flex;
  align-items: center;
`

const StyledTelephoneNumberWrapper = styled(Wrapper)`
  display: inline-flex;
  align-items: center;
  gap: ${GTR.XS};
`

const StyledDivider = styled(Divider)`
  height: ${GTR.XL};

  ${screenMin.l`
    height: ${GTR.XXL};
  `}
`

const ContactUs = (): React.ReactElement => {
  const isProductSupportOpen = useProductSupportStatus(PRODUCTS.WILLS)
  const { telephoneNumber } = PRODUCTS_MAP[PRODUCTS.WILLS]

  return (
    <Wrapper maxWidthInColumns={6}>
      <H size="XS">Call us today</H>
      <P>
        If you have questions about your specific situation, call us to speak to
        a specialist.
      </P>

      <StyledSmallScreenContentWrapper>
        <StyledSpecialistsWrapper margin={['S', 0, 0]}>
          <Wrapper margin={[0, 0, 0, -18]}>
            <Specialists group={SPECIALISTS.WILLS} extraSmall />
          </Wrapper>

          {isProductSupportOpen && (
            <>
              <StyledDivider vertical margin={[0, 'M']} />
              <PhoneLineOpenIndicator text="Line open" />
            </>
          )}
        </StyledSpecialistsWrapper>

        {isProductSupportOpen && (
          <Wrapper margin={['L', 0, 0]}>
            <StyledTelephoneNumber
              telephoneNumber={telephoneNumber}
              showIcon
              highlighted
              hasButtonStyling
            />
          </Wrapper>
        )}
      </StyledSmallScreenContentWrapper>

      <StyledLargeScreenContentWrapper>
        <StyledSpecialistsWrapper margin={['S', 0, 0]}>
          <Specialists group={SPECIALISTS.WILLS} small />

          {isProductSupportOpen && (
            <>
              <StyledDivider vertical margin={[0, 'M']} />
              <Wrapper>
                <StyledTelephoneNumberWrapper>
                  <PhoneIcon />{' '}
                  <TelephoneNumber
                    telephoneNumber={telephoneNumber}
                    showIcon={false}
                  />
                </StyledTelephoneNumberWrapper>
                <PhoneLineOpenIndicator text="Line open - give us a call" />
              </Wrapper>
            </>
          )}
        </StyledSpecialistsWrapper>
      </StyledLargeScreenContentWrapper>
    </Wrapper>
  )
}

export default ContactUs
