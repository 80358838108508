import React from 'react'
import { COLOR } from '@farewill/ui/tokens'

import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'

import ExperimentalLandingPageContent from 'views/wills/components/ExptRecommendWill'

const ExperimentalLandingPage = (): React.ReactElement => (
  <DefaultLayout
    title="Online Will Writing Service | Legal Will In 15 Minutes"
    description={`Our solicitor-approved guide helps you write a will online from the comfort of your own home. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
      TRUSTPILOT_REVIEWS_TOTAL
    )} customers on Trustpilot. National Will Writing Firm of the Year.`}
    product={PRODUCTS.WILLS}
    headerColor={COLOR.WHITE}
    headerShowLoginLink
  >
    <ExperimentalLandingPageContent />
  </DefaultLayout>
)

export default ExperimentalLandingPage
