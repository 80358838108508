import React from 'react'
import { capitalize } from 'lodash-es'
import { graphql, useStaticQuery } from 'gatsby'
import { Button, Wrapper, Divider, H, P } from '@farewill/ui'
import { BREAKPOINT, COLOR } from '@farewill/ui/tokens'

import { CHARITY_AMOUNT_RAISED, WILLS_SIGN_UP_PATH } from 'config'
import { PRODUCTS } from 'lib/products/constants'
import {
  ONLINE_WILL,
  TELEPHONE_WILL,
} from 'views/wills/components/WillOptions/constants'

import AwardBanner from 'components/AwardBanner'
import BreakpointSwitch from 'components/BreakpointSwitch'
import CharitiesList from 'components/CharitiesList'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import LinkButton from 'components/LinkButton'
import PreventOrphan from 'components/PreventOrphan'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'

import Hero from '../Hero'
import WillOptions from '../WillOptions'
import WhyWriteYourWillToday from '../WhyWriteYourWillToday'
import GetStarted from '../GetStarted'
import ContactUs from './ContactUs'

/**
 * This component should only be shown if telephone wills are enabled, so this
 * value can be assumed to be true. Partners and campaigns that do not offer
 * telephone wills are excluded from the A/B experiment by configuration in
 * Contentful which is read in src/lib/gatsby/node/createPagesStatefully/partnerCampaigns.ts.
 */
const TELEPHONE_WILLS_ENABLED = true

export type ExptCombinedWillsLPProps = {
  heroTitle?: string | null
  heroSubtitle?: string | null
  isPartnerCampaignPage?: boolean
}

const ExptCombinedWillsLP = ({
  heroTitle,
  heroSubtitle,
  isPartnerCampaignPage = false,
}: ExptCombinedWillsLPProps): React.ReactElement => {
  const { faqSection } = useStaticQuery(graphql`
    query {
      faqSection: contentfulFaqSection(
        entryTitle: { eq: "Wills A/B experiment Q3 2023" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  return (
    <Wrapper background={COLOR.WHITE}>
      <Wrapper container>
        <Hero
          title={heroTitle || 'The UK’s favourite will writing service'}
          subtitle={
            heroSubtitle ||
            'Write your will online or over the phone and take care of what really matters.'
          }
          isPartnerCampaignPage={isPartnerCampaignPage}
          telephoneWillsEnabled={TELEPHONE_WILLS_ENABLED}
        />
      </Wrapper>
      <Divider container />
      <Wrapper container>
        <BreakpointSwitch
          breakAt={BREAKPOINT.S}
          belowComponent={<TrustpilotCarousel />}
          aboveComponent={<TrustpilotReviews showTitle />}
        />
      </Wrapper>
      <WillOptions
        isFree={isPartnerCampaignPage}
        edgeBackgroundColor={COLOR.WHITE}
        options={[ONLINE_WILL, TELEPHONE_WILL]}
        cardProps={{
          alwaysShowOptionalExtras: true,
        }}
      />
      <Wrapper paddingFromL={['S', 0, 'XL']}>
        <AwardBanner
          title="Will Writing Firm of the Year, 4 years in a row"
          paragraph="For generations, the process of writing a will has been slow, expensive and inconvenient. We created Farewill to change that. Since our launch, we’ve helped tens of thousands of people across the UK to make a will. And every year since 2019 we’ve been crowned National Will Writing Firm of the Year at the British Wills and Probate Awards."
          showDecorativeColor
        />
      </Wrapper>
      <WhyWriteYourWillToday
        cta={
          <LinkButton
            to={WILLS_SIGN_UP_PATH}
            partnerCampaignReferral={isPartnerCampaignPage}
            telephoneWillsEnabled={TELEPHONE_WILLS_ENABLED}
            productLink
            data-track-event="click"
            data-track-element="cta"
            data-track-slice="why-write-your-will-today"
          >
            Get started today
          </LinkButton>
        }
      />
      <Wrapper container>
        <FrequentlyAskedQuestions
          title="Frequently asked questions"
          questions={faqSection.questions}
          selectedProduct={PRODUCTS.WILLS}
          telephoneWillsEnabled={TELEPHONE_WILLS_ENABLED}
          showCallUsTodayCta
          customCta={
            <Wrapper margin={['L', 0, 0]}>
              <ContactUs />
            </Wrapper>
          }
        />
      </Wrapper>
      <Divider container />
      <Wrapper container>
        <H size="L" decorative>
          <PreventOrphan>
            {capitalize(CHARITY_AMOUNT_RAISED)} pledged to charity
          </PreventOrphan>
        </H>
        <P maxWidthInColumns={7}>
          Our generous customers have pledged {CHARITY_AMOUNT_RAISED} to our
          charity partners by leaving a gift in their wills.
        </P>

        <Wrapper
          background={COLOR.BACKGROUND.FOG}
          bordered
          borderRadius="M"
          padding={['M', 0]}
          margin={['L', 0, 0]}
        >
          <CharitiesList withMoreCharitiesText />
        </Wrapper>
      </Wrapper>

      <GetStarted
        cta={
          <LinkButton
            buttonTag={Button.White}
            to={WILLS_SIGN_UP_PATH}
            partnerCampaignReferral={isPartnerCampaignPage}
            telephoneWillsEnabled={TELEPHONE_WILLS_ENABLED}
            productLink
            data-track-event="click"
            data-track-element="cta"
            data-track-slice="todo-list-footer"
          >
            Let’s get started
          </LinkButton>
        }
      />
    </Wrapper>
  )
}

export default ExptCombinedWillsLP
